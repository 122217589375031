<template>
  <a-modal
    :visible="visible"
    :title="isEdit ? '编辑微信特约商户' : '新增微信特约商户'"
    @ok="handleOk"
    @cancel="handleCancel"
    width="600px"
  >
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="商户名称">
        <a-input
          :maxLength="30"
          placeholder="请输入商户名称"
          v-decorator="[
            'accountName',
            {
              initialValue: record.accountName || '',
              rules: [{ required: true, message: '请输入商户名称' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('accountName') }}/30</span
        >
      </a-form-item>
      <a-form-item label="商户号">
        <a-input
          :maxLength="30"
          placeholder="请填写商户号"
          v-decorator="[
            'accountNumber',
            {
              initialValue: record.accountNumber || '',
              rules: [{ required: true, message: '请填写商户号' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('accountNumber') }}/30</span
        >
      </a-form-item>
      <a-form-item label="联系人姓名">
        <a-input
          :maxLength="30"
          placeholder="请输入联系人姓名"
          v-decorator="[
            'nickName',
            {
              initialValue: record.nickName || '',
              rules: [{ required: true, message: '请输入联系人姓名' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('nickName') }}/30</span
        >
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          :maxLength="11"
          placeholder="请输入联系人手机号"
          v-decorator="[
            'mobile',
            {
              initialValue: record.mobile || '',
              rules: [{ required: true, message: '请输入联系人手机号' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('mobile') }}/11</span
        >
      </a-form-item>
      <a-form-item label="联系人邮箱">
        <a-input
          :maxLength="30"
          placeholder="请输入联系人邮箱"
          v-decorator="[
            'email',
            {
              initialValue: record.email || '',
              rules: [{ required: true, message: '请输入联系人邮箱' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('email') }}/30</span
        >
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'WechatMall',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEdit: Object.keys(this.record).length > 0,
      form: this.$form.createForm(this),
    }
  },
  created() {
    this.$nextTick(() => {
      this.$forceUpdate()
    })
  },
  methods: {
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          if (this.isEdit) {
            values = {
              ...values,
              id: this.record.id,
            }
            this.updateWechatMall(values).then(() => {
              this.$message.success('修改成功')
              this.$emit('refresh')
            })
          } else {
            this.addWechatMall(values).then(() => {
              this.$message.success('新增成功')
              this.$emit('refresh')
            })
          }
        }
      })
      // this.$emit('refresh')
    },
    /* 新增商户 */
    addWechatMall(data) {
      return new Promise((resolve, reject) => {
        this.$api.addWechatMall(data).then((res) => {
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 新增商户 */
    updateWechatMall(data) {
      return new Promise((resolve, reject) => {
        this.$api.updateWechatMall(data).then((res) => {
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
