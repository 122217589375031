var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.isEdit ? '编辑微信特约商户' : '新增微信特约商户',"width":"600px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-form-item',{attrs:{"label":"商户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'accountName',
          {
            initialValue: _vm.record.accountName || '',
            rules: [{ required: true, message: '请输入商户名称' }],
          } ]),expression:"[\n          'accountName',\n          {\n            initialValue: record.accountName || '',\n            rules: [{ required: true, message: '请输入商户名称' }],\n          },\n        ]"}],attrs:{"maxLength":30,"placeholder":"请输入商户名称"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('accountName'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"商户号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'accountNumber',
          {
            initialValue: _vm.record.accountNumber || '',
            rules: [{ required: true, message: '请填写商户号' }],
          } ]),expression:"[\n          'accountNumber',\n          {\n            initialValue: record.accountNumber || '',\n            rules: [{ required: true, message: '请填写商户号' }],\n          },\n        ]"}],attrs:{"maxLength":30,"placeholder":"请填写商户号"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('accountNumber'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"联系人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'nickName',
          {
            initialValue: _vm.record.nickName || '',
            rules: [{ required: true, message: '请输入联系人姓名' }],
          } ]),expression:"[\n          'nickName',\n          {\n            initialValue: record.nickName || '',\n            rules: [{ required: true, message: '请输入联系人姓名' }],\n          },\n        ]"}],attrs:{"maxLength":30,"placeholder":"请输入联系人姓名"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('nickName'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobile',
          {
            initialValue: _vm.record.mobile || '',
            rules: [{ required: true, message: '请输入联系人手机号' }],
          } ]),expression:"[\n          'mobile',\n          {\n            initialValue: record.mobile || '',\n            rules: [{ required: true, message: '请输入联系人手机号' }],\n          },\n        ]"}],attrs:{"maxLength":11,"placeholder":"请输入联系人手机号"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('mobile'))+"/11")])],1),_c('a-form-item',{attrs:{"label":"联系人邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            initialValue: _vm.record.email || '',
            rules: [{ required: true, message: '请输入联系人邮箱' }],
          } ]),expression:"[\n          'email',\n          {\n            initialValue: record.email || '',\n            rules: [{ required: true, message: '请输入联系人邮箱' }],\n          },\n        ]"}],attrs:{"maxLength":30,"placeholder":"请输入联系人邮箱"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('email'))+"/30")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }