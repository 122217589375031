<!--
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-04 10:32:30
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-08-09 14:50:28
 * @FilePath: \cz-operation\src\views\tenantManage\wechatMallManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEwechat
-->
<template>
  <div class="wechatMall">
    <div class="title">
      <span> 微信特约商户管理 </span>
      <a-button type="primary" @click="addWechatMall">新增</a-button>
    </div>
    <a-table
      :data-source="dataList"
      :columns="columns"
      :pagination="pagination"
      @change="tableChange"
    >
      <template slot="action" slot-scope="text, record">
        <a @click="edit(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="lock(record, 1)" v-if="record.locked == 0">锁定</a>
        <a @click="lock(record, 0)" v-else>解锁</a>
        <a-divider type="vertical" />
        <a-popconfirm
          title="你确定要删除这行内容吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="del(record)"
          @cancel="() => {}"
        >
          <a href="#">删除</a>
        </a-popconfirm>
        <!-- <a @click="del(record)">删除</a> -->
      </template>
    </a-table>
    <!-- 新增、编辑微信特约商户 -->
    <wechat-mall
      :visible.sync="wechatVisible"
      :record="wechatMallRecord"
      v-if="wechatVisible"
      @refresh="refresh"
    ></wechat-mall>
  </div>
</template>

<script>
import wechatMall from './modals/wechatMalll.vue'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: '商户名称',
    dataIndex: 'accountName',
    key: 'accountName',
  },
  {
    title: '商户号',
    dataIndex: 'accountNumber',
    key: 'accountNumber',
  },
  {
    title: '联系人姓名',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: '联系人手机号',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: '联系人邮箱',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '绑定租户号',
    dataIndex: 'tid',
    key: 'tid',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'WechatMallManage',
  components: { wechatMall },
  data() {
    return {
      columns,
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 15,
        total: 0,
        showTotal: (total) => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '20', '30'],
      },
      wechatMallRecord: {},
      wechatVisible: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getDataList({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      })
    },
    refresh() {
      this.wechatVisible = false
      this.init()
    },
    /* 获取微信特约商户列表 */
    getDataList(params) {
      params = {
        ...params,
        payType: 2,
      }
      return new Promise((resolve, reject) => {
        this.$api.getWeChatMallList(params).then((res) => {
          if (res.code === 0) {
            this.dataList = res.data.records
            this.pagination.total = Number(res.data.total)
            this.dataList.forEach((item, index) => {
              let _index = res.data.limit * (res.data.page - 1) + index + 1
              this.$set(item, 'index', _index)
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    tableChange({ current, pageSize }) {
      this.getDataList({ page: current, limit: pageSize })
      this.pagination.current = current
      this.pagination.pageSize = pageSize
    },
    del(record) {
      let data = { id: record.id }
      this.delWechatMall(data).then(() => {
        this.$message.success('删除成功')
        this.refresh()
      })
    },
    lock(record, value) {
      let params = {
        id: record.id,
        locked: value,
      }
      this.lockWechatMall(params).then(() => {
        this.$message.success('修改成功')
        this.refresh()
      })
    },
    edit(record) {
      this.wechatMallRecord = record
      this.wechatVisible = true
    },
    /* 微信商户编辑窗口 */
    addWechatMall() {
      this.wechatMallRecord = {}
      this.wechatVisible = true
    },
    /* 删除商户 */
    delWechatMall(data) {
      return new Promise((resolve, reject) => {
        this.$api.deleteWechatMall(data).then((res) => {
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 锁定、解锁商户 */
    lockWechatMall(params) {
      return new Promise((resolve, reject) => {
        this.$api.lockWechatMall(params).then((res) => {
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.wechatMall {
  padding: 15px;
}

.title {
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-left: 10px;
    font-family: '.PingFang SC S0pxibold';
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    color: #151515;
    /* 伪类 */
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -5px;
      transform: translateY(-50%);
      width: 4px;
      height: 20px;
      border-radius: 3px;
      background: #1890ff;
    }
  }
}

/deep/ .ant-table-column-title {
  font-weight: bold;
}
</style>
